import routerOptions0 from "/usr/local/jenkins/workspace/appnrvtnl/node_modules/.pnpm/nuxt@3.13.2_eslint@8.57.1_stylelint@16.10.0_typescript@5.6.3_vite@5.4.9/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/usr/local/jenkins/workspace/appnrvtnl/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}