import { default as indexlc8Rt8681eMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/activity-types/add/index.vue?macro=true";
import { default as _91id_93Eb7bcADaEaMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/activity-types/edit/[id].vue?macro=true";
import { default as index54y6E1XTmOMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/activity-types/index.vue?macro=true";
import { default as _91id_93QIzVpcrM31Meta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/certificates/[certificateId]/periods/edit/[id].vue?macro=true";
import { default as _91id_93inUMzqXcp0Meta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/certificates/[certificateId]/periods/view/[id].vue?macro=true";
import { default as indexkGNKt8YHezMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/certificates/add/index.vue?macro=true";
import { default as _91id_93Us5cKue7IgMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/certificates/convert-chambers/[id].vue?macro=true";
import { default as indexJlGFxvWD8ZMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/certificates/departments/index.vue?macro=true";
import { default as _91id_93yOVgFyEAgRMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/certificates/edit/[id].vue?macro=true";
import { default as indexoTgtV6uIVeMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/certificates/index.vue?macro=true";
import { default as indexrC44IOzmr3Meta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/certificates/unsubscribe-requests/index.vue?macro=true";
import { default as indexI6ZoWDHKieMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/certificates/unsubscribed/index.vue?macro=true";
import { default as _91id_93uJhAciF65hMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/certificates/view/[id].vue?macro=true";
import { default as indexk6Ts6vtHTnMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/companies/add/index.vue?macro=true";
import { default as _91id_93NbrwSsHWwrMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/companies/edit/[id].vue?macro=true";
import { default as indexibNH9k2MICMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/companies/index.vue?macro=true";
import { default as _91id_93AEFYPxIomcMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/companies/view/[id].vue?macro=true";
import { default as _91relation_939vQpB1mXgXMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/courses/add/[relation].vue?macro=true";
import { default as indexTQuj8ZX2dNMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/courses/add/index.vue?macro=true";
import { default as _91id_938Xdq2KygEcMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/courses/edit/[id].vue?macro=true";
import { default as _91course_93zaAbntt1YaMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/courses/edit/[relation]/[course].vue?macro=true";
import { default as indexkj3VgwfKu6Meta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/courses/index.vue?macro=true";
import { default as indexF4SsfsrX5AMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/courses/upload-attendance/index.vue?macro=true";
import { default as _91id_93OwRpwFvMz5Meta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/courses/view/[id].vue?macro=true";
import { default as active7ya83i4kvxMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/dashboard/active.vue?macro=true";
import { default as index19DK0OOMcdMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/dashboard/index.vue?macro=true";
import { default as _91bucket_93vUj0HA0Ll1Meta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/dashboard/pe/backlog/[year]/[bucket].vue?macro=true";
import { default as indexRWzCazD9nCMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/dashboard/pe/backlog/[year]/index.vue?macro=true";
import { default as indexyB5WVUQD6lMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/dashboard/pe/index.vue?macro=true";
import { default as subscriptions1EcEehi0QBMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/dashboard/subscriptions.vue?macro=true";
import { default as unsubscriptions8G4Hut83kPMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/dashboard/unsubscriptions.vue?macro=true";
import { default as index1Xa0kne4CrMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/documents/add/index.vue?macro=true";
import { default as _91id_93NNWKokdpm6Meta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/documents/edit/[id].vue?macro=true";
import { default as indexgkHZ3t5oOsMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/documents/index.vue?macro=true";
import { default as indexeKeIVtSSsMMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/index.vue?macro=true";
import { default as index2rbhY6p85fMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/invoices/add/index.vue?macro=true";
import { default as indexs82OqFtRAhMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/invoices/annual/index.vue?macro=true";
import { default as _91id_93tu2OljF3WZMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/invoices/edit/[id].vue?macro=true";
import { default as indexoke1p2MbIlMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/invoices/index.vue?macro=true";
import { default as indexz5dYDufdqMMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/invoices/open/index.vue?macro=true";
import { default as indexV9JRxPq8IWMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/invoices/paid/index.vue?macro=true";
import { default as _91status_93OBF4lU3lpMMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/invoices/send/[status].vue?macro=true";
import { default as indexshrnL59PXfMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/invoices/unpaid/index.vue?macro=true";
import { default as _91id_93TULUdHX04sMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/invoices/view/[id].vue?macro=true";
import { default as indexLAel7ZPrPHMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/login/forgot-password/index.vue?macro=true";
import { default as _91token_938b3tPfXEYZMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/login/forgot-password/reset/[token].vue?macro=true";
import { default as indexe6RP8vnmyFMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/login/index.vue?macro=true";
import { default as indexlnFOjS4EhIMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/relations/activities/[relationId]/add/index.vue?macro=true";
import { default as _91id_93lKmSwiuTY8Meta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/relations/activities/[relationId]/edit/[id].vue?macro=true";
import { default as indexLynZUxaVK8Meta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/relations/add/index.vue?macro=true";
import { default as indexxgjmzjR8q0Meta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/relations/companions/index.vue?macro=true";
import { default as indexKkyeWuP8uqMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/relations/contacts/index.vue?macro=true";
import { default as index20z2KMoYe7Meta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/relations/deleted/index.vue?macro=true";
import { default as index10usAFBOZtMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/relations/departments/index.vue?macro=true";
import { default as indexlmffj1kUMTMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/relations/edit-address/[id]/[movementId]/index.vue?macro=true";
import { default as indexYh1fK5b6YOMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/relations/edit-address/[id]/index.vue?macro=true";
import { default as _91id_93ne4EMaoqspMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/relations/edit/[id].vue?macro=true";
import { default as indexHV0Bffmvn7Meta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/relations/index.vue?macro=true";
import { default as indexg06GJL1VhlMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/relations/masters/index.vue?macro=true";
import { default as _91id_93o2KKuWx0vHMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/relations/movements/[relationId]/edit/[id].vue?macro=true";
import { default as indexT6Csi70bp2Meta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/relations/movements/index.vue?macro=true";
import { default as indexrdmA3u7PPaMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/relations/unsubscribed/index.vue?macro=true";
import { default as _91id_93amhWkayO4LMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/relations/view/[id].vue?macro=true";
import { default as indexRsqZpSrU5bMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/relations/waitingforsubscription/index.vue?macro=true";
import { default as indexxESGb6zy3iMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/reports/add/index.vue?macro=true";
import { default as indexRsDk2ZhpRKMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/reports/index.vue?macro=true";
import { default as index8TOxteQHoJMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/settings/assessments/add/index.vue?macro=true";
import { default as indexNC5SNUUBQfMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/settings/assessments/index.vue?macro=true";
import { default as indexyxsQiKa57YMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/settings/exact/index.vue?macro=true";
import { default as _2020mISlKS9DJvMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/settings/final-score/change/2020.vue?macro=true";
import { default as indexofDWMiTGuvMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/settings/final-score/index.vue?macro=true";
import { default as indexn4soitvwd3Meta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/settings/invoice-codes/add/index.vue?macro=true";
import { default as _91id_930A8WuWyBtqMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/settings/invoice-codes/edit/[id].vue?macro=true";
import { default as indexQalFL9Ud9xMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/settings/invoice-codes/index.vue?macro=true";
import { default as _91id_93qTLm0GjcosMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/settings/invoice-codes/view/[id].vue?macro=true";
import { default as indexyusuAfrbCjMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/settings/pe-points/[id]/combination/add/index.vue?macro=true";
import { default as _91combination_93mhET4LbqtrMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/settings/pe-points/[id]/combination/edit/[combination].vue?macro=true";
import { default as indexmsxFFOKPlpMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/settings/pe-points/[id]/index.vue?macro=true";
import { default as indexfYwf6RgsBEMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/settings/pe-points/add/index.vue?macro=true";
import { default as indexFoAAm4m7hGMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/settings/pe-points/index.vue?macro=true";
import { default as indexdkA2JzGoIYMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/settings/users/add/index.vue?macro=true";
import { default as _91id_93HqoPTpt3TGMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/settings/users/edit/[id].vue?macro=true";
import { default as indexLNY0a8yapOMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/settings/users/index.vue?macro=true";
import { default as index9U0mLSJglNMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/surveylists/add/index.vue?macro=true";
import { default as _91id_93eA1FKLXWz7Meta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/surveylists/edit/[id].vue?macro=true";
import { default as index8cxbkyyDkPMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/surveylists/index.vue?macro=true";
import { default as indexFz5JHQymtcMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/surveylists/options/add/index.vue?macro=true";
import { default as _91id_93lMRw3g8N5yMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/surveylists/options/edit/[id].vue?macro=true";
import { default as indexYerO37UcSHMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/surveylists/options/index.vue?macro=true";
import { default as indexNKbZLQSrt6Meta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/surveylists/optionsets/add/index.vue?macro=true";
import { default as _91id_93NeL5GNZsB3Meta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/surveylists/optionsets/edit/[id].vue?macro=true";
import { default as indexACFracnTE4Meta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/surveylists/optionsets/index.vue?macro=true";
import { default as indexeIJkmNCiStMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/surveylists/participants/[surveylistId]/[participantId]/index.vue?macro=true";
import { default as indexdeP0O27uf3Meta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/surveylists/participants/[surveylistId]/add/index.vue?macro=true";
import { default as indexAKmntOa279Meta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/surveylists/participants/[surveylistId]/index.vue?macro=true";
import { default as indexITpbA8WwxgMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/surveylists/participants/[surveylistId]/select/index.vue?macro=true";
import { default as indexGGqSotaeENMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/surveylists/questions/[surveylistId]/add/index.vue?macro=true";
import { default as _91id_93pO20bgSdzyMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/surveylists/questions/[surveylistId]/edit/[id].vue?macro=true";
import { default as indexRzSat7ARm4Meta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/crm/surveylists/questions/[surveylistId]/index.vue?macro=true";
import { default as _91step_93gDN2NDyIhDMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/extranet/addresscheck/[uuid]/step/[step].vue?macro=true";
import { default as indexbsJ60v2DesMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/extranet/bedankt/index.vue?macro=true";
import { default as indexIVoajZcJ3oMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/extranet/certificates/[certificateId]/add/index.vue?macro=true";
import { default as indexhhIpIcI0e1Meta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/extranet/certificates/[certificateId]/unsubscribe/index.vue?macro=true";
import { default as _91id_93dfC7ZOI97aMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/extranet/certificates/view/[id].vue?macro=true";
import { default as index2QEhOO7c2WMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/extranet/companies/edit/index.vue?macro=true";
import { default as _91id_93K7bOgTOLqCMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/extranet/companies/view/[id].vue?macro=true";
import { default as indexjSgGcFkBbZMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/extranet/index.vue?macro=true";
import { default as asTHb5l6BZ7CMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/extranet/login/as.vue?macro=true";
import { default as indexxLt55HAqKKMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/extranet/login/forgot-password/index.vue?macro=true";
import { default as _91token_93jDHAcw5oLoMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/extranet/login/forgot-password/reset/[token].vue?macro=true";
import { default as indexjTuino5knMMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/extranet/login/index.vue?macro=true";
import { default as indexaPGcOU2Fy0Meta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/extranet/personal/activities/add/index.vue?macro=true";
import { default as _91id_93zFDXSE0A4DMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/extranet/personal/activities/edit/[id].vue?macro=true";
import { default as indexaxPKjCQAIUMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/extranet/personal/movements/index.vue?macro=true";
import { default as indexXYPwaD6oPkMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/extranet/personal/password/index.vue?macro=true";
import { default as index9Fpcm2qpcFMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/extranet/personal/profile/index.vue?macro=true";
import { default as _91step_93znWVJJkHbkMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/extranet/surveylist/[uuid]/step/[step].vue?macro=true";
import { default as thankyouRXITFZ0p8HMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/extranet/surveylist/[uuid]/step/thankyou.vue?macro=true";
import { default as _91uuid_93sr0dgqfCKsMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/extranet/surveylist/intro/[uuid].vue?macro=true";
import { default as _91uuid_93M6mGi7qil0Meta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/extranet/surveylist/outro/[uuid].vue?macro=true";
import { default as _91uuid_93mp6SWOgvyqMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/extranet/surveylist/overview/[uuid].vue?macro=true";
import { default as _91uuid_93eijY2hXUPyMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/extranet/surveylist/review/[uuid].vue?macro=true";
import { default as _91uuid_93FQMe8hTmxiMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/extranet/surveylist/view/[uuid].vue?macro=true";
import { default as indexDEJ9EoeV3KMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/extranet/unsubscribe-all/index.vue?macro=true";
import { default as indexfOvJxpJ5dIMeta } from "/usr/local/jenkins/workspace/appnrvtnl/pages/index.vue?macro=true";
export default [
  {
    name: "crm-activity-types-add",
    path: "/crm/activity-types/add",
    meta: indexlc8Rt8681eMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/activity-types/add/index.vue")
  },
  {
    name: "crm-activity-types-edit-id",
    path: "/crm/activity-types/edit/:id()",
    meta: _91id_93Eb7bcADaEaMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/activity-types/edit/[id].vue")
  },
  {
    name: "crm-activity-types",
    path: "/crm/activity-types",
    meta: index54y6E1XTmOMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/activity-types/index.vue")
  },
  {
    name: "crm-certificates-certificateId-periods-edit-id",
    path: "/crm/certificates/:certificateId()/periods/edit/:id()",
    meta: _91id_93QIzVpcrM31Meta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/certificates/[certificateId]/periods/edit/[id].vue")
  },
  {
    name: "crm-certificates-certificateId-periods-view-id",
    path: "/crm/certificates/:certificateId()/periods/view/:id()",
    meta: _91id_93inUMzqXcp0Meta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/certificates/[certificateId]/periods/view/[id].vue")
  },
  {
    name: "crm-certificates-add",
    path: "/crm/certificates/add",
    meta: indexkGNKt8YHezMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/certificates/add/index.vue")
  },
  {
    name: "crm-certificates-convert-chambers-id",
    path: "/crm/certificates/convert-chambers/:id()",
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/certificates/convert-chambers/[id].vue")
  },
  {
    name: "crm-certificates-departments",
    path: "/crm/certificates/departments",
    meta: indexJlGFxvWD8ZMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/certificates/departments/index.vue")
  },
  {
    name: "crm-certificates-edit-id",
    path: "/crm/certificates/edit/:id()",
    meta: _91id_93yOVgFyEAgRMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/certificates/edit/[id].vue")
  },
  {
    name: "crm-certificates",
    path: "/crm/certificates",
    meta: indexoTgtV6uIVeMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/certificates/index.vue")
  },
  {
    name: "crm-certificates-unsubscribe-requests",
    path: "/crm/certificates/unsubscribe-requests",
    meta: indexrC44IOzmr3Meta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/certificates/unsubscribe-requests/index.vue")
  },
  {
    name: "crm-certificates-unsubscribed",
    path: "/crm/certificates/unsubscribed",
    meta: indexI6ZoWDHKieMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/certificates/unsubscribed/index.vue")
  },
  {
    name: "crm-certificates-view-id",
    path: "/crm/certificates/view/:id()",
    meta: _91id_93uJhAciF65hMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/certificates/view/[id].vue")
  },
  {
    name: "crm-companies-add",
    path: "/crm/companies/add",
    meta: indexk6Ts6vtHTnMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/companies/add/index.vue")
  },
  {
    name: "crm-companies-edit-id",
    path: "/crm/companies/edit/:id()",
    meta: _91id_93NbrwSsHWwrMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/companies/edit/[id].vue")
  },
  {
    name: "crm-companies",
    path: "/crm/companies",
    meta: indexibNH9k2MICMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/companies/index.vue")
  },
  {
    name: "crm-companies-view-id",
    path: "/crm/companies/view/:id()",
    meta: _91id_93AEFYPxIomcMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/companies/view/[id].vue")
  },
  {
    name: "crm-courses-add-relation",
    path: "/crm/courses/add/:relation()",
    meta: _91relation_939vQpB1mXgXMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/courses/add/[relation].vue")
  },
  {
    name: "crm-courses-add",
    path: "/crm/courses/add",
    meta: indexTQuj8ZX2dNMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/courses/add/index.vue")
  },
  {
    name: "crm-courses-edit-id",
    path: "/crm/courses/edit/:id()",
    meta: _91id_938Xdq2KygEcMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/courses/edit/[id].vue")
  },
  {
    name: "crm-courses-edit-relation-course",
    path: "/crm/courses/edit/:relation()/:course()",
    meta: _91course_93zaAbntt1YaMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/courses/edit/[relation]/[course].vue")
  },
  {
    name: "crm-courses",
    path: "/crm/courses",
    meta: indexkj3VgwfKu6Meta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/courses/index.vue")
  },
  {
    name: "crm-courses-upload-attendance",
    path: "/crm/courses/upload-attendance",
    meta: indexF4SsfsrX5AMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/courses/upload-attendance/index.vue")
  },
  {
    name: "crm-courses-view-id",
    path: "/crm/courses/view/:id()",
    meta: _91id_93OwRpwFvMz5Meta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/courses/view/[id].vue")
  },
  {
    name: "crm-dashboard-active",
    path: "/crm/dashboard/active",
    meta: active7ya83i4kvxMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/dashboard/active.vue")
  },
  {
    name: "crm-dashboard",
    path: "/crm/dashboard",
    meta: index19DK0OOMcdMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/dashboard/index.vue")
  },
  {
    name: "crm-dashboard-pe-backlog-year-bucket",
    path: "/crm/dashboard/pe/backlog/:year()/:bucket()",
    meta: _91bucket_93vUj0HA0Ll1Meta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/dashboard/pe/backlog/[year]/[bucket].vue")
  },
  {
    name: "crm-dashboard-pe-backlog-year",
    path: "/crm/dashboard/pe/backlog/:year()",
    meta: indexRWzCazD9nCMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/dashboard/pe/backlog/[year]/index.vue")
  },
  {
    name: "crm-dashboard-pe",
    path: "/crm/dashboard/pe",
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/dashboard/pe/index.vue")
  },
  {
    name: "crm-dashboard-subscriptions",
    path: "/crm/dashboard/subscriptions",
    meta: subscriptions1EcEehi0QBMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/dashboard/subscriptions.vue")
  },
  {
    name: "crm-dashboard-unsubscriptions",
    path: "/crm/dashboard/unsubscriptions",
    meta: unsubscriptions8G4Hut83kPMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/dashboard/unsubscriptions.vue")
  },
  {
    name: "crm-documents-add",
    path: "/crm/documents/add",
    meta: index1Xa0kne4CrMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/documents/add/index.vue")
  },
  {
    name: "crm-documents-edit-id",
    path: "/crm/documents/edit/:id()",
    meta: _91id_93NNWKokdpm6Meta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/documents/edit/[id].vue")
  },
  {
    name: "crm-documents",
    path: "/crm/documents",
    meta: indexgkHZ3t5oOsMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/documents/index.vue")
  },
  {
    name: "crm",
    path: "/crm",
    meta: indexeKeIVtSSsMMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/index.vue")
  },
  {
    name: "crm-invoices-add",
    path: "/crm/invoices/add",
    meta: index2rbhY6p85fMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/invoices/add/index.vue")
  },
  {
    name: "crm-invoices-annual",
    path: "/crm/invoices/annual",
    meta: indexs82OqFtRAhMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/invoices/annual/index.vue")
  },
  {
    name: "crm-invoices-edit-id",
    path: "/crm/invoices/edit/:id()",
    meta: _91id_93tu2OljF3WZMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/invoices/edit/[id].vue")
  },
  {
    name: "crm-invoices",
    path: "/crm/invoices",
    meta: indexoke1p2MbIlMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/invoices/index.vue")
  },
  {
    name: "crm-invoices-open",
    path: "/crm/invoices/open",
    meta: indexz5dYDufdqMMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/invoices/open/index.vue")
  },
  {
    name: "crm-invoices-paid",
    path: "/crm/invoices/paid",
    meta: indexV9JRxPq8IWMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/invoices/paid/index.vue")
  },
  {
    name: "crm-invoices-send-status",
    path: "/crm/invoices/send/:status()",
    meta: _91status_93OBF4lU3lpMMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/invoices/send/[status].vue")
  },
  {
    name: "crm-invoices-unpaid",
    path: "/crm/invoices/unpaid",
    meta: indexshrnL59PXfMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/invoices/unpaid/index.vue")
  },
  {
    name: "crm-invoices-view-id",
    path: "/crm/invoices/view/:id()",
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/invoices/view/[id].vue")
  },
  {
    name: "crm-login-forgot-password",
    path: "/crm/login/forgot-password",
    meta: indexLAel7ZPrPHMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/login/forgot-password/index.vue")
  },
  {
    name: "crm-login-forgot-password-reset-token",
    path: "/crm/login/forgot-password/reset/:token()",
    meta: _91token_938b3tPfXEYZMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/login/forgot-password/reset/[token].vue")
  },
  {
    name: "crm-login",
    path: "/crm/login",
    meta: indexe6RP8vnmyFMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/login/index.vue")
  },
  {
    name: "crm-relations-activities-relationId-add",
    path: "/crm/relations/activities/:relationId()/add",
    meta: indexlnFOjS4EhIMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/relations/activities/[relationId]/add/index.vue")
  },
  {
    name: "crm-relations-activities-relationId-edit-id",
    path: "/crm/relations/activities/:relationId()/edit/:id()",
    meta: _91id_93lKmSwiuTY8Meta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/relations/activities/[relationId]/edit/[id].vue")
  },
  {
    name: "crm-relations-add",
    path: "/crm/relations/add",
    meta: indexLynZUxaVK8Meta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/relations/add/index.vue")
  },
  {
    name: "crm-relations-companions",
    path: "/crm/relations/companions",
    meta: indexxgjmzjR8q0Meta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/relations/companions/index.vue")
  },
  {
    name: "crm-relations-contacts",
    path: "/crm/relations/contacts",
    meta: indexKkyeWuP8uqMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/relations/contacts/index.vue")
  },
  {
    name: "crm-relations-deleted",
    path: "/crm/relations/deleted",
    meta: index20z2KMoYe7Meta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/relations/deleted/index.vue")
  },
  {
    name: "crm-relations-departments",
    path: "/crm/relations/departments",
    meta: index10usAFBOZtMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/relations/departments/index.vue")
  },
  {
    name: "crm-relations-edit-address-id-movementId",
    path: "/crm/relations/edit-address/:id()/:movementId()",
    meta: indexlmffj1kUMTMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/relations/edit-address/[id]/[movementId]/index.vue")
  },
  {
    name: "crm-relations-edit-address-id",
    path: "/crm/relations/edit-address/:id()",
    meta: indexYh1fK5b6YOMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/relations/edit-address/[id]/index.vue")
  },
  {
    name: "crm-relations-edit-id",
    path: "/crm/relations/edit/:id()",
    meta: _91id_93ne4EMaoqspMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/relations/edit/[id].vue")
  },
  {
    name: "crm-relations",
    path: "/crm/relations",
    meta: indexHV0Bffmvn7Meta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/relations/index.vue")
  },
  {
    name: "crm-relations-masters",
    path: "/crm/relations/masters",
    meta: indexg06GJL1VhlMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/relations/masters/index.vue")
  },
  {
    name: "crm-relations-movements-relationId-edit-id",
    path: "/crm/relations/movements/:relationId()/edit/:id()",
    meta: _91id_93o2KKuWx0vHMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/relations/movements/[relationId]/edit/[id].vue")
  },
  {
    name: "crm-relations-movements",
    path: "/crm/relations/movements",
    meta: indexT6Csi70bp2Meta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/relations/movements/index.vue")
  },
  {
    name: "crm-relations-unsubscribed",
    path: "/crm/relations/unsubscribed",
    meta: indexrdmA3u7PPaMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/relations/unsubscribed/index.vue")
  },
  {
    name: "crm-relations-view-id",
    path: "/crm/relations/view/:id()",
    meta: _91id_93amhWkayO4LMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/relations/view/[id].vue")
  },
  {
    name: "crm-relations-waitingforsubscription",
    path: "/crm/relations/waitingforsubscription",
    meta: indexRsqZpSrU5bMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/relations/waitingforsubscription/index.vue")
  },
  {
    name: "crm-reports-add",
    path: "/crm/reports/add",
    meta: indexxESGb6zy3iMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/reports/add/index.vue")
  },
  {
    name: "crm-reports",
    path: "/crm/reports",
    meta: indexRsDk2ZhpRKMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/reports/index.vue")
  },
  {
    name: "crm-settings-assessments-add",
    path: "/crm/settings/assessments/add",
    meta: index8TOxteQHoJMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/settings/assessments/add/index.vue")
  },
  {
    name: "crm-settings-assessments",
    path: "/crm/settings/assessments",
    meta: indexNC5SNUUBQfMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/settings/assessments/index.vue")
  },
  {
    name: "crm-settings-exact",
    path: "/crm/settings/exact",
    meta: indexyxsQiKa57YMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/settings/exact/index.vue")
  },
  {
    name: "crm-settings-final-score-change-2020",
    path: "/crm/settings/final-score/change/2020",
    meta: _2020mISlKS9DJvMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/settings/final-score/change/2020.vue")
  },
  {
    name: "crm-settings-final-score",
    path: "/crm/settings/final-score",
    meta: indexofDWMiTGuvMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/settings/final-score/index.vue")
  },
  {
    name: "crm-settings-invoice-codes-add",
    path: "/crm/settings/invoice-codes/add",
    meta: indexn4soitvwd3Meta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/settings/invoice-codes/add/index.vue")
  },
  {
    name: "crm-settings-invoice-codes-edit-id",
    path: "/crm/settings/invoice-codes/edit/:id()",
    meta: _91id_930A8WuWyBtqMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/settings/invoice-codes/edit/[id].vue")
  },
  {
    name: "crm-settings-invoice-codes",
    path: "/crm/settings/invoice-codes",
    meta: indexQalFL9Ud9xMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/settings/invoice-codes/index.vue")
  },
  {
    name: "crm-settings-invoice-codes-view-id",
    path: "/crm/settings/invoice-codes/view/:id()",
    meta: _91id_93qTLm0GjcosMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/settings/invoice-codes/view/[id].vue")
  },
  {
    name: "crm-settings-pe-points-id-combination-add",
    path: "/crm/settings/pe-points/:id()/combination/add",
    meta: indexyusuAfrbCjMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/settings/pe-points/[id]/combination/add/index.vue")
  },
  {
    name: "crm-settings-pe-points-id-combination-edit-combination",
    path: "/crm/settings/pe-points/:id()/combination/edit/:combination()",
    meta: _91combination_93mhET4LbqtrMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/settings/pe-points/[id]/combination/edit/[combination].vue")
  },
  {
    name: "crm-settings-pe-points-id",
    path: "/crm/settings/pe-points/:id()",
    meta: indexmsxFFOKPlpMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/settings/pe-points/[id]/index.vue")
  },
  {
    name: "crm-settings-pe-points-add",
    path: "/crm/settings/pe-points/add",
    meta: indexfYwf6RgsBEMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/settings/pe-points/add/index.vue")
  },
  {
    name: "crm-settings-pe-points",
    path: "/crm/settings/pe-points",
    meta: indexFoAAm4m7hGMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/settings/pe-points/index.vue")
  },
  {
    name: "crm-settings-users-add",
    path: "/crm/settings/users/add",
    meta: indexdkA2JzGoIYMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/settings/users/add/index.vue")
  },
  {
    name: "crm-settings-users-edit-id",
    path: "/crm/settings/users/edit/:id()",
    meta: _91id_93HqoPTpt3TGMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/settings/users/edit/[id].vue")
  },
  {
    name: "crm-settings-users",
    path: "/crm/settings/users",
    meta: indexLNY0a8yapOMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/settings/users/index.vue")
  },
  {
    name: "crm-surveylists-add",
    path: "/crm/surveylists/add",
    meta: index9U0mLSJglNMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/surveylists/add/index.vue")
  },
  {
    name: "crm-surveylists-edit-id",
    path: "/crm/surveylists/edit/:id()",
    meta: _91id_93eA1FKLXWz7Meta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/surveylists/edit/[id].vue")
  },
  {
    name: "crm-surveylists",
    path: "/crm/surveylists",
    meta: index8cxbkyyDkPMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/surveylists/index.vue")
  },
  {
    name: "crm-surveylists-options-add",
    path: "/crm/surveylists/options/add",
    meta: indexFz5JHQymtcMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/surveylists/options/add/index.vue")
  },
  {
    name: "crm-surveylists-options-edit-id",
    path: "/crm/surveylists/options/edit/:id()",
    meta: _91id_93lMRw3g8N5yMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/surveylists/options/edit/[id].vue")
  },
  {
    name: "crm-surveylists-options",
    path: "/crm/surveylists/options",
    meta: indexYerO37UcSHMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/surveylists/options/index.vue")
  },
  {
    name: "crm-surveylists-optionsets-add",
    path: "/crm/surveylists/optionsets/add",
    meta: indexNKbZLQSrt6Meta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/surveylists/optionsets/add/index.vue")
  },
  {
    name: "crm-surveylists-optionsets-edit-id",
    path: "/crm/surveylists/optionsets/edit/:id()",
    meta: _91id_93NeL5GNZsB3Meta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/surveylists/optionsets/edit/[id].vue")
  },
  {
    name: "crm-surveylists-optionsets",
    path: "/crm/surveylists/optionsets",
    meta: indexACFracnTE4Meta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/surveylists/optionsets/index.vue")
  },
  {
    name: "crm-surveylists-participants-surveylistId-participantId",
    path: "/crm/surveylists/participants/:surveylistId()/:participantId()",
    meta: indexeIJkmNCiStMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/surveylists/participants/[surveylistId]/[participantId]/index.vue")
  },
  {
    name: "crm-surveylists-participants-surveylistId-add",
    path: "/crm/surveylists/participants/:surveylistId()/add",
    meta: indexdeP0O27uf3Meta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/surveylists/participants/[surveylistId]/add/index.vue")
  },
  {
    name: "crm-surveylists-participants-surveylistId",
    path: "/crm/surveylists/participants/:surveylistId()",
    meta: indexAKmntOa279Meta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/surveylists/participants/[surveylistId]/index.vue")
  },
  {
    name: "crm-surveylists-participants-surveylistId-select",
    path: "/crm/surveylists/participants/:surveylistId()/select",
    meta: indexITpbA8WwxgMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/surveylists/participants/[surveylistId]/select/index.vue")
  },
  {
    name: "crm-surveylists-questions-surveylistId-add",
    path: "/crm/surveylists/questions/:surveylistId()/add",
    meta: indexGGqSotaeENMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/surveylists/questions/[surveylistId]/add/index.vue")
  },
  {
    name: "crm-surveylists-questions-surveylistId-edit-id",
    path: "/crm/surveylists/questions/:surveylistId()/edit/:id()",
    meta: _91id_93pO20bgSdzyMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/surveylists/questions/[surveylistId]/edit/[id].vue")
  },
  {
    name: "crm-surveylists-questions-surveylistId",
    path: "/crm/surveylists/questions/:surveylistId()",
    meta: indexRzSat7ARm4Meta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/crm/surveylists/questions/[surveylistId]/index.vue")
  },
  {
    name: "extranet-addresscheck-uuid-step-step",
    path: "/extranet/addresscheck/:uuid()/step/:step()",
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/extranet/addresscheck/[uuid]/step/[step].vue")
  },
  {
    name: "extranet-bedankt",
    path: "/extranet/bedankt",
    meta: indexbsJ60v2DesMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/extranet/bedankt/index.vue")
  },
  {
    name: "extranet-certificates-certificateId-add",
    path: "/extranet/certificates/:certificateId()/add",
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/extranet/certificates/[certificateId]/add/index.vue")
  },
  {
    name: "extranet-certificates-certificateId-unsubscribe",
    path: "/extranet/certificates/:certificateId()/unsubscribe",
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/extranet/certificates/[certificateId]/unsubscribe/index.vue")
  },
  {
    name: "extranet-certificates-view-id",
    path: "/extranet/certificates/view/:id()",
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/extranet/certificates/view/[id].vue")
  },
  {
    name: "extranet-companies-edit",
    path: "/extranet/companies/edit",
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/extranet/companies/edit/index.vue")
  },
  {
    name: "extranet-companies-view-id",
    path: "/extranet/companies/view/:id()",
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/extranet/companies/view/[id].vue")
  },
  {
    name: "extranet",
    path: "/extranet",
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/extranet/index.vue")
  },
  {
    name: "extranet-login-as",
    path: "/extranet/login/as",
    meta: asTHb5l6BZ7CMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/extranet/login/as.vue")
  },
  {
    name: "extranet-login-forgot-password",
    path: "/extranet/login/forgot-password",
    meta: indexxLt55HAqKKMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/extranet/login/forgot-password/index.vue")
  },
  {
    name: "extranet-login-forgot-password-reset-token",
    path: "/extranet/login/forgot-password/reset/:token()",
    meta: _91token_93jDHAcw5oLoMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/extranet/login/forgot-password/reset/[token].vue")
  },
  {
    name: "extranet-login",
    path: "/extranet/login",
    meta: indexjTuino5knMMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/extranet/login/index.vue")
  },
  {
    name: "extranet-personal-activities-add",
    path: "/extranet/personal/activities/add",
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/extranet/personal/activities/add/index.vue")
  },
  {
    name: "extranet-personal-activities-edit-id",
    path: "/extranet/personal/activities/edit/:id()",
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/extranet/personal/activities/edit/[id].vue")
  },
  {
    name: "extranet-personal-movements",
    path: "/extranet/personal/movements",
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/extranet/personal/movements/index.vue")
  },
  {
    name: "extranet-personal-password",
    path: "/extranet/personal/password",
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/extranet/personal/password/index.vue")
  },
  {
    name: "extranet-personal-profile",
    path: "/extranet/personal/profile",
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/extranet/personal/profile/index.vue")
  },
  {
    name: "extranet-surveylist-uuid-step-step",
    path: "/extranet/surveylist/:uuid()/step/:step()",
    meta: _91step_93znWVJJkHbkMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/extranet/surveylist/[uuid]/step/[step].vue")
  },
  {
    name: "extranet-surveylist-uuid-step-thankyou",
    path: "/extranet/surveylist/:uuid()/step/thankyou",
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/extranet/surveylist/[uuid]/step/thankyou.vue")
  },
  {
    name: "extranet-surveylist-intro-uuid",
    path: "/extranet/surveylist/intro/:uuid()",
    meta: _91uuid_93sr0dgqfCKsMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/extranet/surveylist/intro/[uuid].vue")
  },
  {
    name: "extranet-surveylist-outro-uuid",
    path: "/extranet/surveylist/outro/:uuid()",
    meta: _91uuid_93M6mGi7qil0Meta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/extranet/surveylist/outro/[uuid].vue")
  },
  {
    name: "extranet-surveylist-overview-uuid",
    path: "/extranet/surveylist/overview/:uuid()",
    meta: _91uuid_93mp6SWOgvyqMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/extranet/surveylist/overview/[uuid].vue")
  },
  {
    name: "extranet-surveylist-review-uuid",
    path: "/extranet/surveylist/review/:uuid()",
    meta: _91uuid_93eijY2hXUPyMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/extranet/surveylist/review/[uuid].vue")
  },
  {
    name: "extranet-surveylist-view-uuid",
    path: "/extranet/surveylist/view/:uuid()",
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/extranet/surveylist/view/[uuid].vue")
  },
  {
    name: "extranet-unsubscribe-all",
    path: "/extranet/unsubscribe-all",
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/extranet/unsubscribe-all/index.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexfOvJxpJ5dIMeta || {},
    component: () => import("/usr/local/jenkins/workspace/appnrvtnl/pages/index.vue")
  }
]