import { SurveyListAnswers } from '../SurveyListAnswers';
import { BaseFactory } from './BaseFactory';
import { RelationFactory } from './RelationFactory';
import { SurveyListFactory } from './SurveyListFactory';
import { SurveyListAnswerFactory } from './SurveyListAnswerFactory';
import type { ReadSurveyListAnswersDto } from '~/types/SurveyListAnswers';

export class SurveyListAnswersFactory extends BaseFactory<ReadSurveyListAnswersDto, SurveyListAnswers> {
    /* eslint-disable-next-line complexity */
    public toModel(dto: ReadSurveyListAnswersDto): SurveyListAnswers {
        const model = new SurveyListAnswers();

        model.dateStarted = dto.dateStarted;
        model.dateEnded = dto.dateEnded;

        if (dto?.relation && dto.relation?.data) {
            model.relation = (new RelationFactory()).toModel(dto.relation.data);
        }

        if (dto?.surveylist && dto.surveylist?.data) {
            model.surveylist = (new SurveyListFactory()).toModel(dto.surveylist.data);
        }

        if (dto?.answers && dto.answers?.data) {
            model.answers = (new SurveyListAnswerFactory()).toModels(dto.answers.data);
        }

        return model;
    }
}
