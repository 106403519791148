import { BaseFactory } from './BaseFactory';
import type { ReadRelationPoints2019Dto } from '~/types/RelationPoints2019';
import { RelationPoints2019 } from '~/models/RelationPoints2019';

export class RelationPoints2019Factory extends BaseFactory<ReadRelationPoints2019Dto, RelationPoints2019> {
    /* eslint-disable-next-line complexity */
    public toModel(dto: ReadRelationPoints2019Dto): RelationPoints2019 {
        const model = new RelationPoints2019();

        model.mandatory = dto.points?.mandatory || null;

        model.optional = dto.points?.optional || null;

        model.free = dto.points?.free || null;

        model.total = dto.points?.total || null;

        model.year = dto.points?.year || null;

        model.requiredElearningPassed = dto.requiredElearningPassed || false;

        return model;
    }
}
