import { BaseFactory } from './BaseFactory';
import type { ReadCompanyKvkDto } from '~/types/CompanyKvk';
import { CompanyKvk } from '~/models/CompanyKvk';
import { secureUrl } from '~/utils/TextUtils';

export class CompanyKvkFactory extends BaseFactory<ReadCompanyKvkDto, CompanyKvk> {
    // eslint-disable-next-line max-statements, complexity
    public toModel(dto: ReadCompanyKvkDto): CompanyKvk {
        const model = new CompanyKvk();

        model.city = dto?.city;
        model.cocNumber = dto?.cocNumber;
        model.establishmentNumber = dto?.establishmentNumber;
        model.houseNumber = dto?.houseNumber;
        model.houseNumberPostfix = dto?.houseNumberPostfix;
        model.mailbox = dto?.mailbox;
        model.mailboxCity = dto?.mailboxCity;
        model.province = dto?.province;
        model.name = dto?.name;
        model.postal = dto?.postal;
        model.street = dto?.street;
        model.website = secureUrl(dto?.website);
        model.tradeNames = dto?.tradeNames;

        return model;
    }
}
