import { BaseFactory } from './BaseFactory';
import type { ReadRelationPoints2021Dto } from '~/types/RelationPoints2021';
import { RelationPoints2021 } from '~/models/RelationPoints2021';

export class RelationPoints2021Factory extends BaseFactory<ReadRelationPoints2021Dto, RelationPoints2021> {
    /* eslint-disable-next-line complexity */
    public toModel(dto: ReadRelationPoints2021Dto): RelationPoints2021 {
        const model = new RelationPoints2021();

        model.mandatory = dto.points?.mandatory || null;

        model.optional = dto.points?.optional || null;

        model.free = dto.points?.free || null;

        model.total = dto.points?.total || null;

        model.year = dto.points?.year || null;

        model.unsubscribedPoints = dto.points?.unsubscribedPoints || null;

        model.requiredElearningPassed = dto.requiredElearningPassed || false;

        return model;
    }
}
