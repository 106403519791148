import { RelationMovement } from '../RelationMovement';
import { BaseFactory } from './BaseFactory';
import type { ReadRelationMovementDto } from '~/types/RelationMovement';
import { AttachmentFactory } from '~/models/factories/AttachmentFactory';
import { secureUrl } from '~/utils/TextUtils';

export class RelationMovementFactory extends BaseFactory<ReadRelationMovementDto, RelationMovement> {
    /* eslint-disable-next-line max-statements */
    public toModel(dto: ReadRelationMovementDto): RelationMovement {
        const model = new RelationMovement();

        model.id = dto.id;
        model.name = dto?.name;
        model.postal = dto.postal;
        model.city = dto.city;
        model.street = dto.street;
        model.houseNumber = dto.houseNumber;
        model.houseNumberPostfix = dto.houseNumberPostfix;
        model.phone = dto.phone;
        model.phoneMobile = dto.phoneMobile;
        model.movementDate = dto.movementDate;
        model.mailbox = dto?.mailbox;
        model.mailboxCity = dto?.mailboxCity;
        model.mailboxPostal = dto?.mailboxPostal;
        model.website = secureUrl(dto?.website);

        if (dto.attachments) {
            model.attachments = (new AttachmentFactory()).toModels(dto.attachments.data);
        }

        return model;
    }
}
