// eslint-disable-next-line max-lines-per-function
const breadcrumbs = name => ({
    'crm': 'Home',
    'crm-activity-types': 'Activiteit types overzicht',
    'crm-activity-types-add': 'Activiteit type toevoegen',
    'crm-activity-types-edit-id': 'Activiteit type: …',
    'crm-certificates': 'Registraties overzicht',
    'crm-certificates-add': 'Registratie toevoegen',
    'crm-certificates-certificateId-periods-edit-id': 'Periode: …',
    'crm-certificates-certificateId-periods-view-id': 'Periode: …',
    'crm-certificates-departments': 'Vakgroep inschrijvingen',
    'crm-certificates-edit-id': 'Registratie: …',
    'crm-certificates-unsubscribe-requests': 'Wacht op uitschrijving',
    'crm-certificates-unsubscribed': 'Uitgeschreven',
    'crm-certificates-view-id': 'Registratie: …',
    'crm-companies': 'Bedrijven overzicht',
    'crm-companies-add': 'Bedrijf toevoegen',
    'crm-companies-edit-id': 'Bedrijf: …',
    'crm-companies-view-id': 'Bedrijf: …',
    'crm-courses': 'Cursus overzicht',
    'crm-courses-add': 'Cursus toevoegen',
    'crm-courses-add-relation': 'Voeg cursus toe',
    'crm-courses-add-relationId': 'Cursus koppelen',
    'crm-courses-edit-id': 'Cursus: …',
    'crm-courses-upload-attendance': 'Presentielijst uploaden',
    'crm-courses-view-id': 'Cursus: …',
    'crm-dashboard': 'Dashboard',
    'crm-dashboard-active': 'Actieve registraties',
    'crm-dashboard-pe': 'PE',
    'crm-dashboard-pe-backlog-year': 'Achterstand …',
    'crm-dashboard-pe-backlog-year-bucket': 'Overzicht … punten',
    'crm-dashboard-subscriptions': 'Inschrijvingen',
    'crm-dashboard-unsubscriptions': 'Uitschrijvingen',
    'crm-documents': 'Documenten overzicht',
    'crm-documents-add': 'Document toevoegen',
    'crm-documents-edit-id': 'Document: …',
    'crm-exact': 'Exact',
    'crm-invoices': 'Facturen overzicht',
    'crm-invoices-add': 'Factuur aanmaken',
    'crm-invoices-annual': 'Maak jaarlijkse facturen',
    'crm-invoices-open': 'Facturen openstaand',
    'crm-invoices-paid': 'Facturen betaald',
    'crm-invoices-unpaid': 'Facturen onbetaald',
    'crm-invoices-view-id': 'Factuur: …',
    'crm-relations': 'Relatie overzicht',
    'crm-relations-add': 'Relatie toevoegen',
    'crm-relations-companions': 'Gezellen overzicht',
    'crm-relations-contacts': 'Contacten overzicht',
    'crm-relations-deleted': 'Verwijderde relaties',
    'crm-relations-departments': 'Vakgroepleden overzicht',
    'crm-relations-edit-address-id': 'Relatie: …',
    'crm-relations-edit-id': 'Relatie: …',
    'crm-relations-movements': 'Bedrijfswijzigingen',
    'crm-relations-unsubscribed': 'Uitgeschreven relaties',
    'crm-relations-view-id': 'Relatie: …',
    'crm-relations-waitingforsubscription': 'Wacht op inschrijving',
    'crm-reports': 'Rapportages overzicht',
    'crm-reports-add': 'Rapportage genereren',
    'crm-settings': 'Instellingen',
    'crm-settings-assessments': 'Assessments overzicht',
    'crm-settings-assessments-add': 'Assessments toevoegen',
    'crm-settings-exact': 'Instellingen Exact',
    'crm-settings-final-score': 'Eindstand 2020',
    'crm-settings-final-score-change-2020': 'Tekort/overschot toevoegen',
    'crm-settings-invoice-codes': 'Factuurcodes overzicht',
    'crm-settings-invoice-codes-add': 'Factuurcode toevoegen',
    'crm-settings-invoice-codes-edit-id': 'Factuurcode: …',
    'crm-settings-invoice-codes-view-id': 'Factuurcode: …',
    'crm-settings-pe-points': 'Puntentelling overzicht',
    'crm-settings-pe-points-add': 'Nieuw jaar toevoegen',
    'crm-settings-pe-points-id': 'Puntentelling: …',
    'crm-settings-pe-points-id-combination-add': 'Nieuwe combinatie toevoegen',
    'crm-settings-pe-points-id-combination-edit-combination': 'Combinatie wijzigen',
    'crm-settings-users': 'Gebruikers overzicht',
    'crm-settings-users-add': 'Gebruiker toevoegen',
    'crm-settings-users-edit-id': 'Gebruiker: …',
    'crm-settings-users-view-id': 'Gebruiker: …',
    'crm-surveylists': 'Vragenlijsten overzicht',
    'crm-surveylists-add': 'Vragenlijst toevoegen',
    'crm-surveylists-edit-id': 'Vragenlijst: …',
    'crm-surveylists-options': 'Opties',
    'crm-surveylists-options-add': 'Optie toevoegen',
    'crm-surveylists-options-edit-id': 'Optie: …',
    'crm-surveylists-optionsets': 'Optiesets',
    'crm-surveylists-optionsets-add': 'Optieset toevoegen',
    'crm-surveylists-optionsets-edit-id': 'Optieset: …',
    'crm-surveylists-participants-surveylistId': 'Deelnemers',
    'crm-surveylists-participants-surveylistId-participantId': 'Antwoorden van: …',
    'crm-surveylists-questions-surveylistId': 'Vragenlijst: …',
}[name]) || name;

export default breadcrumbs;
