import { BaseFactory } from './BaseFactory';
import type { ReadRelationPoints2020Dto } from '~/types/RelationPoints2020';
import { RelationPoints2020 } from '~/models/RelationPoints2020';

export class RelationPoints2020Factory extends BaseFactory<ReadRelationPoints2020Dto, RelationPoints2020> {
    /* eslint-disable-next-line complexity */
    public toModel(dto: ReadRelationPoints2020Dto): RelationPoints2020 {
        const model = new RelationPoints2020();

        model.mandatory = dto.points?.mandatory || null;

        model.optional = dto.points?.optional || null;

        model.free = dto.points?.free || null;

        model.total = dto.points?.total || null;

        model.year = dto.points?.year || null;

        model.requiredElearningPassed = dto.requiredElearningPassed || false;

        return model;
    }
}
